// Translated
<template>
  <AuthForm class="login-page pb-12 mb-12 -mt-4 flex flex-col items-center">
    <AuthFeedback minimal />
    <div
      v-if="stageIsSignup"
      class="login-form w-full flex flex-col"
    >
      <LoginWithFacebook />
      <LoginWithGoogle />
      <TextInput
        id="email"
        v-model="email"
        :placeholder="$t('formEmail')"
        :v$="v$"
        @on-click-enter="signup"
      >
        <template #label>
          <span data-i18n="formEmail">
            {{ $t('formEmail') }}
          </span>
        </template>
        <template #validation="v">
          <span
            v-if="!v?.email"
            data-i18n="formFieldInvalidEmail"
          >{{ $t('formFieldInvalidEmail') }}</span>
          <span
            v-else-if="!v?.required"
            data-i18n="formFieldRequiredError"
          >{{ $t('formFieldRequiredError') }}</span>
        </template>
      </TextInput>
      <TextInput
        id="password"
        v-model="password"
        type="password"
        :placeholder="$t('formPassword')"
        :v$="v$"
        @on-click-enter="signup"
      >
        <template #label>
          <span data-i18n="formPassword">
            {{ $t('formPassword') }}
          </span>
        </template>
        <template #validation="v">
          <span
            v-if="!v?.minLength"
            data-i18n="formFieldInvalidPassword"
          >{{ $t('formFieldInvalidPassword') }}</span>
          <span
            v-else-if="!v?.required"
            data-i18n="formFieldRequiredError"
          >{{ $t('formFieldRequiredError') }}</span>
        </template>
      </TextInput>
      <NewsletterCheckbox
        ref="newsletterCheckbox"
        :email="email"
      />
      <SubmitButton
        id="login-submit"
        :disabled="signupLoading"
        :loading="signupLoading"
        data-i18n="memberSignup"
        @click="signup"
      >
        {{ $t('memberSignup') }}
      </SubmitButton>
      <nuxt-link
        class="block mt-4"
        :to="authLinkTo(AuthPages.login)"
        data-i18n="memberLogIn"
      >
        {{ $t('memberLogIn') }}
      </nuxt-link>
      <nuxt-link
        class="block mt-4"
        :to="authLinkTo(AuthPages.whatsThis)"
        data-i18n="memberWhatsThisTitle"
      >
        {{ $t('memberWhatsThisTitle') }}
      </nuxt-link>
    </div>
    <div
      v-else-if="stageIsVerify"
      class="login-form w-full"
    >
      <p data-i18n="memberAuthErrorEmailValidation">
        {{ $t('memberAuthErrorEmailValidation') }}
      </p>
      <button
        class="btn btn-submit-md"
        :disabled="resendLoading"
        @click="resendCode"
      >
        <span
          v-if="resendLoading"
          data-i18n="formSending"
        >{{ $t('formSending') }}...</span>
        <span
          v-else-if="resendSuccess"
          data-i18n="formSent"
        >{{ $t('formSent') }}!</span>
        <span
          v-else
          data-i18n="formResendCode"
        >{{ $t('formResendCode') }}</span>
      </button>
      <a
        class="text-center block mt-4"
        data-i18n="goBack"
        @click="goTo('signup')"
      >{{ $t('goBack') }}</a>
    </div>
    <Loading v-else />
  </AuthForm>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, minLength, required } from '@vuelidate/validators'
import statuses from '@layers/web/constants/statuses'
import { AuthPages } from '@layers/web/utils/auth/auth'
import cognitoMixin from '@layers/web/mixins/cognito.mixin'

const STAGES = {
  verify: 'VERIFY',
  signup: 'SIGNUP',
}

export default defineNuxtComponent({
  setup: () => ({ v$: useVuelidate() }),

  middleware: 'auth',

  mixins: [cognitoMixin],
  auth: false,

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
  },

  data () {
    return {
      email: '',
      password: '',
      error: false,
      stage: STAGES.signup,
      signupLoading: false,
      resendLoading: false,
      resendSuccess: false,
      timer: null,
      AuthPages,
    }
  },

  computed: {
    stageIsSignup () {
      return this.stage === STAGES.signup
    },

    stageIsVerify () {
      return this.stage === STAGES.verify
    },
  },

  created () {
    this.resendSuccess = false
    this.resendLoading = false

    const current = this.$router.currentRoute.value
    if (current?.query?.resendCode) {
      this.reVerifyUser(current?.query?.resendCode)
    }
  },

  beforeUnmount () {
    clearTimeout(this.timer)
  },

  methods: {
    async signup () {
      this._clearState()
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.signupLoading = true
      const response = await this.cognitoHandleSignup(this.email, this.password)

      if (response.status === statuses.ok) {
        this.stage = STAGES.verify
        await this.$refs.newsletterCheckbox.registerEmailIfChecked()
      }

      this.signupLoading = false
    },

    async resendCode () {
      this.resendLoading = true
      this.resendSuccess = false
      const response = await this.cognitoResendVerification(this.email)
      this.state = response.payload

      if (response.status === statuses.ok) {
        this.resendSuccess = true
        this.timer = window.setTimeout(() => {
          this.resendSuccess = false
        }, 2500)
      }

      this.resendLoading = false
    },

    goTo (stageKey) {
      this.stage = STAGES?.[stageKey] || STAGES.signup
    },

    reVerifyUser (email) {
      this.stage = STAGES.verify
      this.email = email
      this.resendCode()
    },
  },
})
</script>
